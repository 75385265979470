import React, { createContext, useContext, useState } from "react";
import SERVICES from "../services/services";
import Utils, { LABEL } from "../utils/constant";
import { showToast } from "../helper/toastHelper";
import { setAuthToken } from "../services/api";
import { useDispatch } from "react-redux";
import { updateCategories } from "../redux/userSlice";
import moment from "moment";

// Create the context
const RegistrationAndAuthContext = createContext();

// Provider component to wrap around the app
export const RegistrationAndAuthProvider = ({ children }) => {
  const today = moment();
  const maxDate = today.subtract(18, "years").subtract(1, "day");

  const dispatch = useDispatch();
  const [answers, setAnswers] = useState({});
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
    dob: "",
    interest: "",
    profession: "",
  });
  const [categories, setCategories] = useState([""]);

  const [loginUserInfo, setLoginUserInfo] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "dob" && moment(value).isAfter(maxDate))
      value = maxDate.format("YYYY-MM-DD");
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddCategory = (value) => {
    setCategories([...categories, ""]);
  };

  const handleUpdateCategory = (value, index) => {
    setCategories([
      ...categories.slice(0, index),
      value,
      ...categories.slice(index + 1),
    ]);
  };

  const handleDelCategory = (index) => {
    setCategories([
      ...categories.slice(0, index),
      ...categories.slice(index + 1),
    ]);
  };

  const handleChangeLogin = (e) => {
    const { name, value } = e.target;
    setLoginUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAnswerChange = (questionId, selectedOption) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedOption,
    }));
  };

  // Function to send answers to backend
  const submitAnswers = async (cb) => {
    const { confirmPassword, dob, ...restUserInfo } = userInfo;
    const formattedAnswers = {
      ...restUserInfo,
      dob: Utils.ISOStringdate(dob),
      categories,
      answers: answers,
    };
    const result = await SERVICES.createAccount(formattedAnswers);

    if (result?.statusCode === 201) {
      showToast(result?.message, LABEL.TOAST_TYPE.SUCCESS);
      localStorage.setItem("access_token", result?.data?.access_token);
      localStorage.setItem("user", JSON.stringify(result?.data?.user));
      dispatch(updateCategories(result?.data?.user?.categories ?? []));
      setAuthToken(result?.data?.access_token);
      cb();
    } else {
      // showToast(result?.message,LABEL.TOAST_TYPE.SUCCESS);
    }
  };

  // Function to send answers to backend
  const loginUser = async (cb) => {
    const result = await SERVICES.loginUserAccountService(loginUserInfo);

    if (result?.statusCode === 200) {
      showToast(result?.message, LABEL.TOAST_TYPE.SUCCESS);
      localStorage.setItem("access_token", result?.data?.access_token);
      localStorage.setItem("user", JSON.stringify(result?.data?.user));
      dispatch(updateCategories(result?.data?.user?.categories ?? []));
      setAuthToken(result?.data?.access_token);
      setLoginUserInfo({
        email: "",
        password: "",
      });
      cb();
    }
  };

  const value = {
    answers,
    setAnswers,
    userInfo,
    setUserInfo,
    handleChange,
    handleAnswerChange,
    submitAnswers,
    loginUserInfo,
    setLoginUserInfo,
    loginUser,
    handleChangeLogin,
    categories,
    handleAddCategory,
    handleUpdateCategory,
    handleDelCategory,
  };

  return (
    <RegistrationAndAuthContext.Provider value={value}>
      {children}
    </RegistrationAndAuthContext.Provider>
  );
};

// Custom hook to use the RegistrationAndAuthContext
export const useRegistrationAndAuthContext = () =>
  useContext(RegistrationAndAuthContext);
