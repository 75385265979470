import React, { useEffect, useRef, useState } from "react";
import attchFile from "../../assets/images/attach.svg";
import send from "../../assets/images/send.svg";
import microphone from "../../assets/images/microphone.svg";
import { showToast } from "../../helper/toastHelper";
import { LABEL } from "../../utils/constant";

const SearchBox = ({
  isLoading,
  question,
  handleSubmitQuestion,
  handleChangeQuestion,
}) => {
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);

  useEffect(() => {
    if ("webkitSpeechRecognition" in window || "SpeechRecognition" in window) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      recognitionRef.current = new SpeechRecognition();

      recognitionRef.current.lang = "en-US";
      recognitionRef.current.interimResults = false;
      recognitionRef.current.continuous = true;

      recognitionRef.current.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join("");
        handleChangeQuestion(transcript, true);
      };

      recognitionRef.current.onend = () => setIsListening(false);
      recognitionRef.current.onerror = () =>
        showToast("Speech recognition failed", LABEL.TOAST_TYPE.ERROR);
    } else {
      showToast(LABEL.CHAT.ERROR_MESSAGE.SNSB, LABEL.TOAST_TYPE.ERROR);
    }
  }, []);

  const startListening = () => {
    if (recognitionRef.current && !isListening) {
      recognitionRef.current.start();
      setIsListening(true);
    }
  };

  const stopListening = () => {
    if (recognitionRef.current && isListening) {
      recognitionRef.current.stop();
      setIsListening(false);
    }
  };

  const handleAskQuestion = () => {
    stopListening();
    handleSubmitQuestion();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") handleAskQuestion();
  };

  return (
    <div className='searchbox-sticky'>
      <div className='search_bx_cont_wrap'>
        <div className='search_bx_cont'>
          <input
            disabled={isLoading}
            value={question}
            type='text'
            className='textType'
            placeholder='Write Here..'
            onChange={(e) => handleChangeQuestion(e.target.value)}
            onKeyDown={handleKeyDown}
          />

          <button
            className='sendBtn'
            disabled={isLoading || !question}
            onClick={handleAskQuestion}>
            <img src={send} alt='send' />
          </button>
        </div>

        <button
          className={isListening ? "microphone-active" : "microphone"}
          onClick={isListening ? stopListening : startListening}>
          <img src={microphone} alt='microphone' />
        </button>
      </div>
    </div>
  );
};

export default SearchBox;
